const $ = require('jquery');
global.$ = global.jQuery = $;

const AOS = require('aos');
require('aos/dist/aos.css');
require('slick-carousel');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
require('bootstrap');

$(document).ready(function() {
  AOS.init(
    {
      duration: 2000
    }
  );
});
